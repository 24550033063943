import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../../../modules/common/services/organization.service';
import { PageInfoService } from '../../layout';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RoutePath } from './routing.enum';


@Injectable({
    providedIn: 'root',
})
export class RoutingUtil {
    public routeSubject = new Subject<void>();
    route$ = this.routeSubject.asObservable();
    artiFacts: any;
    jsonData: any = {
        "organization": "",
        "overview": "/organization/:org/overview",
        "members": "/organization/:org/members",
        "set-up": "/organization/:org/set-up/department",
        "payslip": "/organization/:org/payslip",
        "team-structure": "/organization/:org/team-structure",
        "asset":"/asset",
        "integrations": "/integrations/settings",
        "access-management": "/access-management",
        "hiring": "",
        "career-page": "/hiring/career-page",
        "jobs": "/hiring/JobPage",
        "candidates-management": "/hiring/candidates-management",
        "candidates": "/hiring/candidates",
        "evaluation-management": "/hiring/evaluation-management",
        "evaluation": "/hiring/evaluation",
        "questions": "/hiring/questions",
        "templates": "/hiring/templates",
        "set-upp": "/hiring/setUp",
        "hiring_integrations": "/hiring/integration",
        "attendance": "/attendance",
        "request": "",
        "all-request": "/request/all-request",
        "apply-leave": "/request/apply-leave",
        "salary": "",
        "deduction": "/salary/deduction",
        "salary-component": "/salary/salary-component",
        "template": "/salary/template",
        "subscription": "",
        "purchase-orders": "/subscription/purchase-orders",
        "plan": "/subscription/plan",
        "payment": "/subscription/payment",
        "reports": "/reports",
        "suggestions": "/suggestions",
        "catalog": "",
        "products": "/catalog/product",
        "categories": "/catalog/categories",
        "customer-management": "/catalog/customers",
        "vendor-management": "/catalog/vendor",
        "sales-set-up": "/catalog/sales-set-up",
        "orders": "/catalog/orders",
        "vendor-orders": "/catalog/vendor-orders",
        "sales-account": "/catalog/sales-account",
        "customer-orders": "/catalog/customer-orders",
        "barcode": "/catalog/barcode",
        "social": "",
        "followers": "/social/followers",
        "feeds": "/social/feeds",
        "on-boarding": "/organization/:org/on-boarding",
        "pip": "/organization/:org/pip",
        "exit": "/organization/:org/exit",
        "opportunity": "/crm/opportunity",
        "clients": "/crm/clients",
        "project-management": "/project-management",
        "project": "/project-management/:org/overview",
        "teams": "/project-management/teams",
        "project-management-invoice": "/project-management/invoice-management",
        "timesheet": "/project-management/timesheet",
        "website-builder": "/website-builder",
        "documents":"/organization/:org/documents",
        "report":"/organization/:org/report",
        "sales-inquiry":"/catalog/mart-inquiry",        
        "track-visit":"/catalog/track-visit",
        "organization-branch":"/organization/:org/organization-branch",
        "availability":`/${RoutePath.RoutePath_134 + '/' + RoutePath.RoutePath_133}`,
        "event-types":`/${RoutePath.RoutePath_134 + '/' + RoutePath.RoutePath_135}`,
        "meetings":`/${RoutePath.RoutePath_134 + '/' + RoutePath.RoutePath_136}`,
        "production-set-up":"production-management/set-up",
        "production":"",
        "bill-of-material":"production-management/bill-of-material",
        "production-order":"production-management/production-order",
        "purchase-inquiry":"/purchase/inquiry",        
        "purchase-quotation":"/purchase/quotation",        
        "purchase-purchase-order":"/purchase/purchase-order",        
        "purchase-inward":"/purchase/inward",
        "purchase-grn":"/purchase/grn",
    }
    roleId: string;
    selectedItem: any;
    org: any;
    subModuleJsonData: any = {
        "all-request": "/request/all-request",
        "apply-leave": "/request/apply-leave"
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private organizationService: OrganizationService,
        private pageInfoService: PageInfoService,
    ) {

    }
    public triggerRouteOrg() {
        this.routeSubject.next();
    }
    public setItem(data: any): void {
        this.selectedItem = data;
    }

    public getItem(): any {
        return this.selectedItem;
    }

    public navigateToParentAndChild(parentItem: any, child?: any) {
        const parentName = parentItem.name;
        const childName = child?.name;
        const list = this.organizationService.getArtifactsValue();
        const parent = list.find((item: any) => item.name === parentName);
        if (parent) {
            if (parent.children && parent.children.length > 0 && childName) {
                const child = parent.children.find((item: any) => item.name === childName);
                if (child && this.jsonData[child.slug]) {
                    this.organizationService.setUserAccess(this.getArtifactAndParent(list, childName));
                    const module: any =
                    {
                        'list': list,
                        'childName': childName
                    }
                    this.pageInfoService.setTitle(childName);
                    localStorage.setItem('module', JSON.stringify(module))
                    this.selectedItem = child.slug
                    this.setItem(this.selectedItem)
                    this.triggerRouteOrg();
                    this.navigateToUrl(this.jsonData[child.slug], parentItem.name);
                    return;
                }
            }
            if (this.jsonData[parent.slug]) {
                this.organizationService.setUserAccess(this.getArtifactAndParent(list, parentName));
                const module: any =
                {
                    'list': list,
                    'childName': parentName
                }
                localStorage.setItem('module', JSON.stringify(module))

                this.pageInfoService.setTitle(parentName);
                this.selectedItem = parentItem.slug
                this.setItem(this.selectedItem)
                this.triggerRouteOrg();
                this.navigateToUrl(this.jsonData[parent.slug], 'Home');
                return;
            }
        }
    }
    filterArtifactsBySlug(slug: string, artifactsFromStorage: any[], parent: any = null): any[] {
        try {
            let filteredArtifacts: any[] = [];
            artifactsFromStorage.forEach((artifact: any) => {
                if (artifact.slug === slug) {
                    if (parent) {
                        filteredArtifacts.push(parent, artifact);
                    } else {
                        filteredArtifacts.push(artifact);
                    }
                }
                if (artifact.children) {
                    const childArtifacts = this.filterArtifactsBySlug(slug, artifact.children, artifact);
                    filteredArtifacts = filteredArtifacts.concat(childArtifacts);
                }
            });
            return filteredArtifacts;
        } catch (error) {
            return [];
        }
    }



    public reverseNavigateToUrl(dynamicUrl: string): { url: string } {
        const orgId = dynamicUrl.split('/')[4];
        const restoredUrl = dynamicUrl.replace(orgId, ':org');
        const titleMap: Record<string, string> = {
            '/dashboard': 'Dashboard',
            '/suggestions': 'Suggestions',
        };
        
        let title = titleMap[restoredUrl] || 'Home';
        let Breadcrumbs = 'Home';
        let slug = title.toLowerCase();
        
        if (restoredUrl && !titleMap[restoredUrl]) {
            const value = this.findParentSlug(restoredUrl, this.jsonData);
            const item: any = localStorage.getItem('artiFacts')
            if (value) {
                const data = this.filterArtifactsBySlug(value, JSON.parse(item));
                Breadcrumbs = data?.length > 1 ? data[0]?.name : 'Home';
                title = data?.[1]?.name || data?.[0]?.name;
                slug = data?.length > 1 ? data[1]?.slug : data[0]?.slug;
                
            }
        }
        if (title != 'Home' || Breadcrumbs != 'Home' || slug != 'home') {
            this.pageInfoService.setTitle(title);
            
            this.pageInfoService.setBreadcrumbs([
                { title: Breadcrumbs, path: dynamicUrl, isSeparator: false, isActive: false },
                { title: '', path: '', isSeparator: true, isActive: false },
            ]);
            this.setItem(slug);
            this.triggerRouteOrg();
            let module: any = localStorage.getItem('artiFacts');
            const data = JSON.parse(module);
            this.organizationService.setUserArtifacts(this.getDataByName(data,this.pageInfoService.getBreadcrumbs()))
            
            
            
        } else {
            const data = this.fetchRouteData()
            if (data?.title) {
                this.pageInfoService.setTitle(data?.title);
                this.pageInfoService.setBreadcrumbs([
                    { title: data?.Breadcrumbs, path: data?.url ?? dynamicUrl, isSeparator: false, isActive: false },
                    { title: '', path: '', isSeparator: true, isActive: false },
                ]);
                this.setItem(data?.slug);
                this.triggerRouteOrg();
                let module: any = localStorage.getItem('artiFacts');
                const data1 = JSON.parse(module);
                this.organizationService.setUserArtifacts(this.getDataByName(data1,this.pageInfoService.getBreadcrumbs()))
                
            }
        }

        return { url: restoredUrl };
    }

    fetchRouteData() {
        const childRoute = this.getChild(this.route);
        const routeData = childRoute.snapshot.data;
        return routeData
    }

    getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    findParentSlug(url: string, jsonData: any): string | null {
        let parentSlug: string | null = null;

        Object.keys(jsonData).forEach(slug => {
            if (jsonData[slug] === url) {
                parentSlug = slug;
                return;
            }
        });

        return parentSlug;
    }

    public formatArtifact(artifact: any) {
        return artifact ? { ...artifact } : null;
    }

    public getArtifactAndParent(artifacts: any[], artifactName: string) {
        let artifact = null;
        let parent = null;
        let organization: any = localStorage.getItem('organization');
        organization = JSON.parse(organization);
        this.roleId = organization.user_role;


        const findArtifact = (data: any) => {
            if (data.name === artifactName) {
                artifact = {
                    id: data.id,
                    name: data.name,
                    is_create: this.roleId === 'ADMIN' ? true : (data.is_create || false),
                    is_read: this.roleId === 'ADMIN' ? true : (data.is_read || false),
                    is_update: this.roleId === 'ADMIN' ? true : (data.is_update || false),
                    is_delete: this.roleId === 'ADMIN' ? true : (data.is_delete || false)
                };

                parent = data.parent_id ? this.formatArtifact(artifacts.find(item => item.id === data.parent_id)) : null;
            } else {
                data.children?.forEach(findArtifact);
            }
        };

        artifacts.forEach(findArtifact);

        return { artifact, parent };
    }


    public navigateToUrl(url: string, name: any) {
        this.org = Number(localStorage.getItem('Org_Id'));
        const dynamicUrl = url.replace(':org', this.org);
        const newArray = [
            {
                title: name,
                path: dynamicUrl,
                isSeparator: false,
                isActive: false
            }
        ];
        newArray.push({
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        });
        this.pageInfoService.setBreadcrumbs(newArray);
        let module: any = localStorage.getItem('artiFacts');
        const data = JSON.parse(module);
        this.organizationService.setUserArtifacts(this.getDataByName(data,this.pageInfoService.getBreadcrumbs()))
        this.router.navigateByUrl(dynamicUrl);
    }

    getDataByName(data:any,name: any) {
        let titleName:any;
        if(name.title === 'Home'){
            titleName = name.child
        }else{
            titleName = name.title
        }
        const foundData = data?.find((item:any) => item?.name?.toLowerCase() === titleName?.toLowerCase());
        if(foundData != undefined && foundData?.children?.length > 0){

            const firstChild = foundData?.children?.find((item:any) => item?.name?.toLowerCase() === name.child?.toLowerCase());
            return firstChild || [];
        }
        return foundData || [];
      }

    public filterArrayBySlug(): any[] {

        let dataArray: any = localStorage.getItem('artiFacts');
        dataArray = JSON.parse(dataArray);
        const urlSegments = this.router.url.split('/');
        const parentSlug = urlSegments[1];
        const childSlug = isNaN(Number(urlSegments[2])) ? urlSegments[2] : urlSegments[3];

        const filteredByParentSlug = dataArray?.filter((item: any) => item?.slug === parentSlug);

        if (filteredByParentSlug?.length === 0) {
            return [];
        }

        const foundChild = childSlug ? filteredByParentSlug[0].children.find((item: any) => item.slug === childSlug) : null;
        this.navigateToParentAndChild(filteredByParentSlug[0], foundChild);

        return foundChild ? [filteredByParentSlug[0], foundChild] : [filteredByParentSlug[0]];
    }


}