<ng-container *ngIf="appFooterContainerCSSClass">
  <!--begin::Footer container-->
  <div class="app-container" [ngClass]="appFooterContainerCSSClass">
    <ng-container *ngTemplateOutlet="footerView"></ng-container>
  </div>
  <!--end::Footer container-->
</ng-container>

<ng-container *ngIf="!appFooterContainerCSSClass">
  <ng-container *ngTemplateOutlet="footerView"></ng-container>
</ng-container>

<ng-template #footerView>
  <!--begin::Copyright-->
  <div class="text-dark order-2 order-md-1" id="printDiv">
    <span class="text-muted fw-semibold me-1">{{currentDateStr}}&copy;</span>
    <a href="https://bnbrun.com/" target="_blank" class="text-gray-800 text-hover-primary">BNB Run</a>
  </div>
  <!--end::Copyright-->

</ng-template>
