import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-control-error',
  template: `
    <div *ngIf="control?.invalid && control?.touched">
        <div *ngIf="control?.hasError('required')" class="fv-plugins-message-container">
            <span role="alert">This field is required.</span>
        </div>
        <div *ngIf="control?.hasError('minlength')" class="fv-plugins-message-container">
            <span role="alert">Minimum length is {{ control.errors?.minlength.requiredLength }}.</span>
        </div>
        <!-- Add more error checks and messages as needed -->
    </div>
  `,
  standalone: true,
})
export class FormControlErrorComponent {
  @Input() control: any;

}
