import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';

@Injectable({
  providedIn: 'root'
})
export class VendorService extends ConnectBaseService{
data: any[];

createVendor(body: any): Observable<any> {
    return this.post(`sales/vendor/?organization_id=${this.org()}`, body);
}

getVendorRetrieve(id: any): Observable<any> {
  return this.get(`sales/vendor/${id}/?organization_id=${this.org()}`);
}

getTaxclass(): Observable<any> {
  return this.get(`sales/vendor-tax/?organization_id=${this.org()}`);
}

getUom(all_result?: any): Observable<any> {
  let params = `?organization_id=${this.org()}`;
  if (all_result) {
    params += `&all_result=${all_result}`;
}
return this.get(`organization-uom/${params}`);
}

getVendor(searchText?: string, ordering?: any, all_result?: any, type?:boolean): Observable<any> {
  let params = `?organization_id=${this.org()}`;
  if (searchText) {
      params += `&search=${searchText}`;
  }
  if (ordering) {
      params += `&${ordering}`;
  }
  if (type) {
      params += `&${type}`;
  }
  if (all_result) {
      params += `&all_result=${all_result}`;
  }
  return this.get(`sales/vendor/${params}`);
}
  
updateParams(queryParams: any, sortingParams: any): any {
  queryParams?.keys()?.forEach((key:any) => {
      if (!sortingParams.has(key) && queryParams.has(key)) {
          sortingParams = sortingParams.append(key, queryParams.get(key));
      }
  });
  return sortingParams;
}

updateVendor(body: any, id: any): Observable<any> {
    return this.put(`sales/vendor/${id}/?organization_id=${this.org()}`, body);
}

deleteVendor(id: any): Observable<any> {
  return this.delete(`sales/vendor/${id}/?organization_id=${this.org()}`);
}


convertToValueLabelArray(inputArray: any[], idKey: string, nameKey: string): any[] {
  if (nameKey.includes(',')) {      
    return inputArray.map(item => {
      let label = '';
      const keys = nameKey.split(',');
      for (const key in keys) {          
        label += `${item[keys[key]]} `
      }
      return {
        value: item[idKey],
        label: label
      }
    });      
  } else {
    return inputArray.map(item => ({
      value: item[idKey],
      label: item[nameKey]?.toString()
    }));
  }    
}

//vendor

getVendorInvoice(searchText?: string, ordering?: any,all_result?: any, type?:boolean, vendorId?: any,filterField?: string, order_listing?: any): Observable<any> {
  let params = `?organization_id=${this.org()}`;
  if (filterField) {
    params += `&${filterField}`;
  }
  if (searchText) {
      params += `&search=${searchText}`;
  }
  if (ordering) {
      params += `&${ordering}`;
  }
  if (type) {
      params += `&${type}`;
  }
  if (all_result) {
      params += `&all_result=${all_result}`;
  }
  if(vendorId){
    params += `&vendor_id=${vendorId}`;
}
  if (order_listing) {
    params += `&order_listing=${order_listing}`;
  }
  return this.get(`sales/vendor-invoice/${params}`);
}

createVendorInvoice(body: any): Observable<any> {
  return this.post(`sales/vendor-invoice/?organization_id=${this.org()}`, body);
}

updateVendorInvoice(body: any, id: any): Observable<any> {
  return this.put(`sales/vendor-invoice/${id}/?organization_id=${this.org()}`, body);
}

vendorInvoiceUpdate(body: any, id: any): Observable<any> {
  return this.patch(`sales/vendor-invoice/${id}/?organization_id=${this.org()}`, body);
}

getVendorInvoiceRetrieve(id: any): Observable<any> {
  return this.get(`sales/vendor-invoice/${id}/?organization_id=${this.org()}`);
}

deleteVendorInvoice(id: any): Observable<any> {
return this.delete(`sales/vendor-invoice/${id}/?organization_id=${this.org()}`);
}

getVendorPaymentTransactions(invoice_id?: any) {
  let params = `?organization_id=${this.org()}`;
  if (invoice_id) {
    params += `&invoice_id=${invoice_id}`;
  }
  return this.get(`sales/vendor-invoice-payment/${params}&all_result=true`);
}

updateVendorPaymentTransaction(body: any, id: any): Observable<any> {
  return this.put(`sales/vendor-invoice-payment/${id}/?organization_id=${this.org()}`, body);
}
addVendorPaymentTransaction(body: any) {
  return this.post(`sales/vendor-invoice-payment/?organization_id=${this.org()}`, body);
}
deleteVendorPaymentTransaction(id: any) {
  return this.delete(`sales/vendor-invoice-payment/${id}/?organization_id=${this.org()}`)
}
getVendorPaymentTransactionRetrieve(id: any): Observable<any> {
  return this.get(`sales/vendor-invoice-payment/${id}/?organization_id=${this.org()}`);
}
}
