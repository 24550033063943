export enum apiUrl {
    Attendance_excel_report= 'attendance-report/excel',
    appoinment_event_type= 'appointment/event-type',
    appointment_meetings= 'appointment/slot-booking',
    production_setup_store = 'production/store',
    production_setup_routing = 'production/routing',
    production_setup_othercharges = 'production/other-charges',
    production_document_series = 'production/document/series',
    production_bom = 'production/bom',
    production_order = 'production/order',
    product_sku = 'product/sku',
    product_sku_date = 'product/sku/data',
    production_order_cycle = 'production-order-cycle',
    integration_sqlServer = 'attendance/biometric/server',
    integration_downloadBiometricData = 'attendance/biometric/server/download',
    get_available_slots = 'get-available-slots',
    get_purchase_inquiry = 'purchase/inquiry',
    get_purchase_public_inquiry = 'purchase/public/inquiry',
    get_purchase_po = 'purchase/order',
    get_purchase_inward = 'purchase/inward',
    get_public_purchase_quotation = 'purchase/public/quotation',
    get_purchase_quotation = 'purchase/quotation',
    get_pruchase_grn =  'purchase/grn',
    get_public_sales_tax_class = 'sales/public/vendor-tax',
}