<div class="mb-5 m-xl-3 rounded border border-secondary">
  <!--begin::Header-->
  <div class="card-header pt-5 justify-content-start">
    <button type="button" data-repeater-create="" class="btn btn-sm text-success border border-success border-1" style="margin-right: 30px;">
      <a class="fa fa-download text-success fa-sm btn-sm me-2 cursor-pointer"></a>Download Item Template
    </button>
    <button type="button" data-repeater-create="" class="btn btn-sm text-success border border-success border-1" style="margin-right: 30px;">
      <a class="fa fa-upload text-success fa-sm btn-sm me-2 cursor-pointer"></a>Bulk Upload
    </button>
    
    <form class="w-30 ms-auto position-relative" autoComplete="off">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'" class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"></span>
      <input type="text" class="form-control form-control-solid px-15" name="search" placeholder="Search" [formControl]="searchControl" />
    </form>
  </div>
  <!--end::Header-->
  <div class="card-body pb-3 px-2 table-responsive">
    <!--begin::Table container-->
    <div class="">
      <!--begin::Table-->
      <form [formGroup]="form">
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
          <!--begin::Table head-->
          <thead class="odd-even-bg-color-table custom-table-border">
            <tr class="fw-bold text-muted bg-light">
              <th class="min-w-50px"></th>
              <th class="min-w-200px">Item</th>
              <th class="min-w-200px">Description</th>
              <th class="min-w-200px">Variation</th>
              <th class="min-w-200px">UOM</th>
              <th class="min-w-200px">Alternate UOM</th>
              <th class="min-w-200px">HSN/SAC Code</th>
              <th class="min-w-200px">Quantity</th>
              <th class="min-w-200px">Delivery Date</th>
              <th class="min-w-200px">Comment</th>
              <th class="min-w-200px">Store Quantity</th>
            </tr>
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody formArrayName="items" class="odd-even-bg-color-table custom-table-border">
            <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
              <td class="p-1 text-center">
                <a *ngIf="items.length > 1" class="fa fa-circle-minus fs-7 text-danger btn-sm cursor-pointer" 
                  (click)="removeItem(i)"></a>
              </td>
              <td class="p-1 min-w-150px">
                <select2 [overlay]="overlay" class="form-control form-control-solid" [data]="newProductlist"
                  formControlName="sales_product"></select2>
              </td>
              <td class="p-1 min-w-150px">
                <input type="text" class="form-control form-control-solid" formControlName="description" />
              </td>
              <td class="p-1 min-w-150px">
                <select2 [overlay]="overlay" class="form-control form-control-solid" [data]="newProductvariationlist"
                  formControlName="product_variant"></select2>
              </td>
              <td class="p-1 min-w-150px">
                <select2 [overlay]="overlay" class="form-control form-control-solid" [data]="newListUom"
                  formControlName="uom"></select2>
              </td>
              <td class="p-1 min-w-150px">
                <select2 [overlay]="overlay" class="form-control form-control-solid" [data]="newListUom"
                  formControlName="alternate_uom"></select2>
              </td>
              <td class="p-1">
                <input type="number" class="form-control form-control-solid" formControlName="hsn_code" />
              </td>
              <td class="p-1">
                <input type="number" class="form-control form-control-solid" formControlName="qty" />
              </td>
              <td class="p-1 min-w-150px">
                <input type="date" class="form-control form-control-solid" formControlName="delivery_date" />
              </td>
              <td class="p-1 min-w-150px">
                <input type="text" class="form-control form-control-solid" formControlName="comment" />
              </td>
              <td class="p-1 min-w-150px">
                <input type="number" class="form-control form-control-solid" formControlName="store_qty" />
              </td>
            </tr>
          </tbody>
          <!--end::Table body-->
        </table>
      </form>
      <button type="button" (click)="addItem()" class="btn btn-sm btn-light-primary" style="margin-right: 30px;">
        <span class="svg-icon svg-icon-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)"
              fill="currentColor" />
            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>Add Item
      </button>
    </div>
  </div>